<template>
  <div class="pt-6 px-4">
    <div class="flex justify-between items-center mb-4">
      <h1 class="text-xl fot-bold text-gray-400">
        Rank Cheker
        <span class="text-xl fot-bold text-gray-800 inline-block ml-2" v-if="site !==''">{{site}}</span>
      </h1>
      <p>
        <button class="sm:inline-flex ml-5 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded text-sm px-2 py-1 text-center items-center" @click="toggleFlag()">Checker Add</button>
      </p>
    </div>
    <div class="" v-if="loading">
      <Loading />
    </div>
    <div class="" v-else>
      <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
        <h1 class="font-semibold">Top Query</h1>
        <input type="text" name="site" class="border-b-1 border-gray-100 w-full my-4" placeholder="Input keyword" v-model="keyword" />
        <table class="w-full">
          <thead class="bg-gray-50">
            <tr>
              <th class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Keyword</th><th>Site</th>
              <th v-for="(d, di) in dates" :key="'di-' + di"
               class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{$dayjs(d).format('M/D')}}
              </th>
              <th class="p-4 text-left text-xs font-medium uppercase tracking-wider">fun</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(q, qi) in list" :key="'qi-' + qi">
              <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                <a class="text-blue-500" :href="'https://www.google.com/search?q=' + q.w" target="_blank">
                  {{q.w}}
                </a>
              </td>
              <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                {{q.site.name}}
              </td>
              <td v-for="(r, ri) in q.ranks" :key="'ri-' + ri"
              class="p-4 whitespace-nowrap text-sm font-normal text-gray-900 text-center"
              >
                {{r}}
              </td>
              <td>
                <button type="button" @click="deleteQuery(q.id)">削除</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-8 text-center">
          <Pager :page="page" :totalPage="Math.ceil(total / perpage)" v-if="total" />
        </div>
      </div>
    </div>
    <CheckerAdd v-if="checkeradd" />
  </div>
</template>


<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { db } from "../db";
import Loading from '@/components/Atom/Loading'
import Pager from '@/components/Blocks/Pager'
import CheckerAdd from '@/components/Blocks/CheckerAdd'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs';

export default {
  name: 'CheckerTop',
  data() {
    return {
      checkeradd: false
    };
  },
  setup() {
    let loading = ref(true);
    const store = useStore()
    const setMsg = (val) => store.dispatch('setMsg', val);
    const setmsgBox = (val) => store.dispatch('setmsgBox', val);
    const route = useRoute()

    let site = ref('');
    let sid = ref(0);
    let total = ref(0);
    let allsites = ref([]);
    let page = ref(0);
    let offset = ref(0);
    let keyword = ref('');
    const dates = ref([]);
    const list = ref([]);
    const perpage = ref(20);

    const setPageParams = ()=> {
      page.value = (route.query.page)? parseInt(route.query.page): 1;
      offset.value = (page.value > 0)? parseInt((page.value - 1)) * perpage.value: 0;
      dates.value = [];
    }

    const getAllsites = async () => {
      allsites.value = await db.sites.toArray();
    }

    const getSiteData = async () => {
      if(route.params.id){
        const data = await db.sites.where('name').equals(route.params.id).first();
        sid.value = data.id;
        site.value = data.name;
      }else{
        sid.value = 0;
        site.value = '';
      }
      await getDatas(sid.value);
    }

    const getDatas = async (sid) => {
      loading.value = true;
      setPageParams(); // パラメータセット
      list.value = []; // 一覧を初期化
      let qlist = [];
      let tmpQuery = db.queries;
      if(sid !== 0){
        tmpQuery = tmpQuery.where('sid').equals(sid);
      }else{
        tmpQuery = tmpQuery.where('sid').above(0);
      }
      if(keyword.value !== ''){
        const grep = new RegExp(keyword.value);
        tmpQuery = tmpQuery.and(
           (item) => {
            if(item.w.match(grep)){
              return item;
            }
          }
        );
      }
      total.value = await tmpQuery.count();
      qlist = await tmpQuery.offset(offset.value).limit(perpage.value).toArray();

      let tmpday = '';
      let count = 0;
      while (count < 7) {
        count++;
        tmpday = dayjs().subtract(3, 'day').subtract(count, 'day');
        dates.value.push(tmpday.format("YYYY-MM-DD"));
      }
      for(let q in qlist){
        const result = {};
        result.site = allsites.value.filter(x => x.id === qlist[q].sid)[0];
        result.w = qlist[q].w;
        result.id = qlist[q].id;

        result.ranks = []
        // 日付ごとのランクチェック
        for(let d in dates.value){
          const rank = await db.scq.where({
            'sid': result.site.id,
            'date': dates.value[d].replace(/-/g, ''),
            'w': qlist[q].w
          }).first();
          if(rank !== undefined){
            result.ranks.push(rank.pos.toFixed(1));
          }else{
            result.ranks.push('-');
          }
        }

        list.value.push(result);
      }
      loading.value = false;
    }
    getAllsites();
    getSiteData();

    watch(route, () => {
      getSiteData()
    })
    watch(keyword, () => {
      getSiteData()
    })

    return {
      keyword,
      db,
      setMsg,
      setmsgBox,
      site,
      sid,
      total,
      dates,
      list,
      loading,
      page,
      perpage,
      offset
    }
  },
  provide() {
    return {
      toggleFlag: this.toggleFlag
    }
  },
  components: {
    Loading,
    Pager,
    CheckerAdd
  },
  methods: {
    toggleFlag(){
      this.checkeradd = (this.checkeradd)? false:true;
    },
    async deleteQuery(id){
      this.setMsg('Queryを削除します');
      this.setmsgBox(true);
      console.log(id);
      await this.db.queries.delete(id)
      .then(async function() {
      })
      .catch(function callback (error) {
        console.log(error);
      });
      this.setMsg('Queryを削除しました。リロードします。');
      await this.$filters.sleep(500);
      this.setmsgBox(false);

      this.$router.go({path: this.$router.currentRoute.path, force: true})

    },
  }
}
</script>
